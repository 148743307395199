// dependencies.
import { useEffect, useRef, useState } from 'react'
import { doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../templates/AdminPanel'
import {
  CardsDesktopItem,
  CardsDesktopSort,
  CardsDesktopWrapper,
  CardsMobileItem,
  CardsMobileSort,
  CardsMobileWrapper,
} from '../../components/screens/Cards'
import { Drawer, PreviewFrame, PREVIEWFRAME_HEIGHT } from '../../components/Modal'
import { Loader } from '../../components/Icon'
import { ReportCard } from '../../components/Card'
import { Form } from '../../components/Form'
import { FilterList } from '../../components/Typography/FilterChip'
import Header from '../../components/Layout/Header'
import ToggleFilterButton from '../../components/Filters/ToggleFilterAsideButton'
// import { BranchSelect } from '../../components/Form'
import FilterDate from '../../components/Filters/Date'
import FilterCardType from '../../components/Filters/CardType'
import FilterResponsible from '../../components/Filters/Responsible'
import FilterLocation from '../../components/Filters/Location'
import ToggleSortingButton from '../../components/Filters/ToggleSortingTooltipButton'
import SearchCardsById from '../../components/screens/Cards/SearchCardsById'
// utils.
import notifyToast from '../../js/notifyToast'
import useFetchCardsData from '../../js/cards/hooks/useFetchCardsData'
import { db } from '../../js/firebase/firebase'
import { useDimensions } from '../../js/hooks'
import { useAppDispatch, useAppSelector } from '../../js/store/hooks'
import { cardUpdated, clearFilters, removeFilter, setFilters } from '../../js/cards/cardsSlice'
import { selectUsersData, selectUsersForSelect } from '../../js/users/usersSelectors'
import {
  selectActiveFilters,
  selectCardTypeFilter,
  selectFilteredCards,
  selectFromDateFilter,
  selectLocationFilter,
  selectSearchTerm,
  selectToDateFilter,
} from '../../js/cards/cardsSelectors'
// constants.
import {
  NAVIGATION_WIDTH,
  NAVIGATION_CONDENSED_WIDTH,
  FILTERS_ASIDE_WIDTH,
  HEADER_HEIGHT_DESKTOP,
} from '../../constants'
import { CHANGE_CARD_RESPONSIBLE_FORM } from './constants'
import { selectCardsLocationsData, selectLocationsTree } from '../../js/locations/locationsSelectors'

// main component.
const AdminCardsScreen = ({ navMenu, navTitle }) => {
  const scrollRef = useRef()
  const { isMobile } = useDimensions()

  // fetch cards data.
  const loaded = useFetchCardsData()
  const dispatch = useAppDispatch()

  const users = useAppSelector(selectUsersData)
  const responsiblesSelectOptions = useAppSelector(selectUsersForSelect)
  const locations = useAppSelector(selectCardsLocationsData)
  const filteredCardsSelector = selectFilteredCards(users, locations)
  const filteredCards = useAppSelector(filteredCardsSelector)
  const locationsTree = useAppSelector(selectLocationsTree)
  const activeFiltersSelector = selectActiveFilters(users, locationsTree)
  const activeFilters = useAppSelector(activeFiltersSelector)

  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [focusedObject, setFocusedObject] = useState(null)
  const [offsetTop, setOffsetTop] = useState(0)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [sortingOpen, setSortingOpen] = useState(false)
  const [navIsCondensed, setNavIsCondensed] = useState(false)

  const searchTerm = useAppSelector(selectSearchTerm)
  const cardTypeFilter = useAppSelector(selectCardTypeFilter)
  const fromDateFilter = useAppSelector(selectFromDateFilter)
  const toDateFilter = useAppSelector(selectToDateFilter)
  const locationFilter = useAppSelector(selectLocationFilter)

  // open modal.
  const handleModalOpen = (open) => setModalOpen(open)

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS
  const handleChangeCardResponsibleModal = async (originalObj) => {
    const obj = {
      ...originalObj,
      responsibleId: originalObj.responsibleRef ? originalObj.responsibleRef.id : null,
    }

    setModalContent({
      title: 'Cambiar responsable',
      cardId: obj.ref.id,
      component: (
        <Form
          items={CHANGE_CARD_RESPONSIBLE_FORM(responsiblesSelectOptions)}
          defaultValues={obj}
          onClick={(obj) => handleChangeCardResponsibleClick(obj)}
        />
      ),
    })
    setModalOpen(true)
  }

  // UPDATE DATABASE DATA
  const handleChangeCardResponsibleClick = async (obj) => {
    try {
      const updateFields = {
        responsibleRef: doc(db, 'users', obj.responsibleId),
        updatedDate: new Date(),
      }

      const cardRef = doc(db, 'cards', obj.ref.id)

      await setDoc(cardRef, updateFields, { merge: true })

      const updatedCardDoc = { ...obj, ...updateFields }
      dispatch(cardUpdated(updatedCardDoc))
      notifyToast('Tarjeta actualizada correctamente', 'success')
      setTimeout(() => setModalOpen(false), 1000)
    } catch (error) {
      console.error('Error al actualizar la tarjeta:', error)
      notifyToast('Ha ocurrido un error al actualizar la tarjeta', 'error')
    }
  }

  // ------------------------------------------------------------------------------------------- //

  // update positions on scroll.
  useEffect(() => {
    dispatch(clearFilters())

    if (typeof scrollRef.current === 'undefined') return // prevent build from failing.
    scrollRef.current.addEventListener('scroll', () => setOffsetTop(scrollRef.current.scrollTop))

    return () =>
      scrollRef.current &&
      scrollRef.current.removeEventListener('scroll', () =>
        setOffsetTop(scrollRef.current.scrollTop)
      )
  }, [])
  const maybeMoveOnScroll = offsetTop > HEADER_HEIGHT_DESKTOP
  const dektopOffsetTranslateY = HEADER_HEIGHT_DESKTOP - offsetTop

  // open preview frame on card-focus.
  const handleFocusedobject = (bool, obj) => {
    if (bool) return setFocusedObject(obj)
    else return setFocusedObject(null)
  }

  // return content.
  return (
    <>
      <AdminTemplate
        ref={scrollRef}
        sectionTitle={navTitle}
        navigation={navMenu}
        isMobile={isMobile}
        modalOpen={modalOpen}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        filterComponents={[
          <SearchCardsById
            key="in-cards-search"
            items={filteredCards}
            defaultValue={searchTerm}
            onSearch={(term) => {
              dispatch(setFilters({ searchTerm: term }))
            }}
          />,
          <FilterDate
            key="in-cards-date"
            label="Filtrar en rango de fechas"
            items={filteredCards}
            defaultValue={{ fromDate: fromDateFilter, toDate: toDateFilter }}
            onDateFilter={(fromDate, toDate) => dispatch(setFilters({ fromDate, toDate }))}
          />,
          <FilterCardType
            key="in-cards-type"
            label="Tipo de Tarjeta"
            items={filteredCards}
            defaultValue={cardTypeFilter}
            onTypeFilter={(type) => dispatch(setFilters({ type }))}
          />,
          <FilterLocation
            key="in-cards-location"
            label="Ubicación"
            placeholder="Buscar ubicaciones"
            value={locationFilter}
            items={filteredCards}
            locationsSelectTree={locationsTree}
            onLocationFilter={(locationId) => dispatch(setFilters({ locationId }))}
          />,
          <FilterResponsible
            key="in-cards-responsible"
            label="Responsable"
            placeholder="Responsable"
            items={filteredCards}
            responsibles={responsiblesSelectOptions}
            onResponsibleFilter={(responsibleId) => dispatch(setFilters({ responsibleId }))}
          />,
        ]}
        onOverlayClick={() => setModalOpen(false)}
        onCondensedChange={(isCondensed) => setNavIsCondensed(isCondensed)}
      >
        <Header justifyContent="flex-start">
          <ToggleFilterButton
            isActive={filtersOpen}
            onChange={(isOpen) => setFiltersOpen(isOpen)}
          />
          {isMobile ? (
            <ToggleSortingButton onChange={(isOpen) => setSortingOpen(isOpen)} />
          ) : (
            <FilterList
              items={activeFilters}
              onFilterClick={(filterValue) => dispatch(removeFilter(filterValue))}
              onClearAllClick={() => dispatch(clearFilters())}
            />
          )}
        </Header>

        {loaded === 'succeeded' ? (
          isMobile ? (
            <CardsMobileWrapper isEmpty={filteredCards.length <= 0}>
              <CardsMobileSort
                items={filteredCards}
                responsibles={users}
                isOpen={sortingOpen}
                onSort={(e) => dispatch(setFilters({ sort: e }))}
              />

              {filteredCards.map((card) => (
                <CardsMobileItem
                  key={card.id}
                  item={card}
                  onEdit={() => handleChangeCardResponsibleModal(card)}
                />
              ))}
            </CardsMobileWrapper>
          ) : (
            <CardsDesktopWrapper isEmpty={filteredCards.length <= 0}>
              <CardsDesktopSort
                items={filteredCards}
                responsibles={users}
                style={{
                  ...(maybeMoveOnScroll && {
                    maxWidth: `calc(100% - ${
                      filtersOpen
                        ? navIsCondensed
                          ? `${NAVIGATION_CONDENSED_WIDTH + FILTERS_ASIDE_WIDTH}px`
                          : `${NAVIGATION_WIDTH + FILTERS_ASIDE_WIDTH}px`
                        : navIsCondensed
                          ? `${NAVIGATION_CONDENSED_WIDTH}px`
                          : `${NAVIGATION_WIDTH}px`
                    })`,
                    position: 'fixed',
                  }),
                }}
                onSort={(e) => dispatch(setFilters({ sort: e }))}
              />

              {filteredCards.map((card, i) => (
                <CardsDesktopItem
                  key={card.id}
                  item={card}
                  isEven={i % 2 === 0}
                  onEdit={() => handleChangeCardResponsibleModal(card)}
                  onFocus={(bool) => handleFocusedobject(bool, card)}
                />
              ))}

              <PreviewFrame
                onCloseClick={() => handleFocusedobject(false, {})}
                items={
                  !!focusedObject && [
                    <ReportCard
                      key={focusedObject.id}
                      type={focusedObject.type}
                      card={focusedObject}
                    />,
                  ]
                }
                style={
                  offsetTop > 0
                    ? {
                        height: maybeMoveOnScroll
                          ? '100vh'
                          : PREVIEWFRAME_HEIGHT(dektopOffsetTranslateY),
                        top: maybeMoveOnScroll ? 0 : `${dektopOffsetTranslateY}px`,
                      }
                    : {
                        height: PREVIEWFRAME_HEIGHT(),
                        top: HEADER_HEIGHT_DESKTOP,
                      }
                }
              />
            </CardsDesktopWrapper>
          )
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default AdminCardsScreen

// dependencies.
import { useEffect, useState } from 'react'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../../templates/AdminPanel'
import Table from '../../../components/Table'
import Header from '../../../components/Layout/Header'
// import BranchSelect from '../../components/Filters/BranchSelect'
import { Button } from '../../../components/Button'
import { Drawer } from '../../../components/Modal'
// import { Breadcrumbs } from '../../components/Layout'
import { Form } from '../../../components/Form'
import { Loader } from '../../../components/Icon'
// utils.
import notifyToast from '../../../js/notifyToast'
import { db, getLocationById } from '../../../js/firebase/firebase'
import { useDimensions } from '../../../js/hooks'
// constants.
import FloatingButton from '../../../components/Button/MobileButton'
// import Search from '../../../components/Filters/Search'

// constants.'
import {
  selectLocationsStatus,
  makeSelectLocationsForAuditsTable,
  selectLocationsForWorkstationSelect,
} from '../../../js/locations/locationsSelectors'
import { useAppDispatch, useAppSelector } from '../../../js/store/hooks'
import { fetchLocations, subscribeToLocations } from '../../../js/locations/locationsSlice'
import { selectUsersStatus } from '../../../js/users/usersSelectors'
import { fetchUsers } from '../../../js/users/usersSlice'
import { NEW_AUDIT_LOCATION_FORM } from '../../Admin/constants'
/********************************/
/*                              */
/*    Admin Locations Screen    */
/*                              */
/********************************/

// helpers.
const tableHeader = [
  { label: 'Nombre', grow: 2 },
  { label: 'Área ', grow: 2 },
  { label: 'Subárea', grow: 2 },
  { label: 'Acciones', grow: 2 },
]

// main component.
const AuditsLocationsScreen = ({ navMenu }) => {
  // states.
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  // hooks.
  const { isMobile } = useDimensions()
  const areas = useAppSelector(selectLocationsForWorkstationSelect)
  const selectLocationsForTable = makeSelectLocationsForAuditsTable()
  const locationsTable = useAppSelector(selectLocationsForTable)

  // data object.
  const locationsTableitems = locationsTable
  const dispatch = useAppDispatch()
  const loaded = useAppSelector(selectLocationsStatus)
  const usersStatus = useAppSelector(selectUsersStatus)

  useEffect(() => {
    if (usersStatus === 'idle') {
      dispatch(fetchUsers()).then(() => {
        // Wait until fetchusers is done
        if (loaded === 'idle') {
          dispatch(fetchLocations())
        }
      })
    } else if (usersStatus === 'succeeded' && loaded === 'idle') {
      dispatch(fetchLocations())
    }
    const unsubscribe = dispatch(subscribeToLocations())

    return () => unsubscribe() // Cleanup subscription on unmount
  }, [dispatch, loaded])

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => {
    setModalOpen(open)
  }

  // handle add new location modal.
  const handleNewLocationModal = () => {
    setModalContent({
      title: `Agregar nuevo puesto de trabajo`,
      component: (
        <Form
          items={NEW_AUDIT_LOCATION_FORM(areas)}
          onClick={(obj) => handleCreateLocationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle edit selected location modal.
  const handleEditLocationModal = (originalObj) => {
    const obj = {
      ...originalObj,
      parent: originalObj.parentRef ? originalObj.parentRef.id : null,
    }

    setModalContent({
      title: `Actualizar puesto de trabajo`,
      component: (
        <Form
          items={NEW_AUDIT_LOCATION_FORM(areas)}
          defaultValues={obj}
          onClick={(obj) => handleSaveLocationClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new location.
  const handleCreateLocationClick = async (obj) => {
    const parent = await getLocationById(obj.parent)
    if (!parent) {
      notifyToast('La ubicación seleccionada no existe', 'error')
      return
    }
    const location = {
      name: obj.name,
      level: 3,
      isAuditable: true,
      parentRef: parent.ref,
    }

    location.createdDate = new Date()
    location.updatedDate = new Date()

    await addDoc(collection(db, 'locations'), location)
      .then(async (locationRef) => {
        await setDoc(locationRef, { ref: locationRef }, { merge: true })
        notifyToast(`Puesto de trabajo creado correctamente`, 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // handle location information update.
  const handleSaveLocationClick = async (obj) => {
    const parent = await getLocationById(obj.parent)
    if (!parent) {
      notifyToast('La ubicación seleccionada no existe', 'error')
      return
    }
    const updateFields = {
      name: obj.name,
      parentRef: parent.ref,
      updatedDate: new Date(),
      isAuditable: obj.isAuditable,
    }

    const locationRef = doc(db, 'locations', obj.ref.id)
    await setDoc(locationRef, updateFields, { merge: true })
      .then(() => notifyToast(`Puesto de trabajo guardado correctamente`, 'success'))
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle="Puestos de trabajo"
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        {' '}
        <Header justifyContent="right">
          {/* <Search

        placeholder="Buscar por nombre..."
        onChange={(term) => {
          dispatch(setFilters({ searchTerm: term }))
        }}
      /> */}
          {isMobile ? (
            <>
              <FloatingButton onClick={() => handleNewLocationModal()} />
              {/* <ToggleSortingButton onChange={(isOpen) => setSortingOpen(isOpen)} /> */}
            </>
          ) : (
            <Button onClick={() => handleNewLocationModal()}>Agregar puesto de trabajo</Button>
          )}
        </Header>
        {/* <Breadcrumbs pathname="/auditorias/stop/ubicaciones" items={breadcrumbs} /> */}
        {loaded === 'succeeded' ? (
          <Table
            header={tableHeader}
            items={locationsTableitems}
            //
            enableEdit
            onEdit={(obj) => handleEditLocationModal(obj)}
          />
        ) : (
          <Loader />
        )}
      </AdminTemplate>

      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default AuditsLocationsScreen

// dependencies.
import styled from 'styled-components'
// components.
import { Icon } from '../Icon'
// utils.
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'
// constants.
import { HEADER_HEIGHT_DESKTOP, PREVIEW_FRAME_WIDTH } from '../../constants'

// helpers.
export const PREVIEWFRAME_HEIGHT = (offset = HEADER_HEIGHT_DESKTOP) => `calc(100vh - ${offset}px)`

const baseUnit = theme.fontSize.x16
const FRAME_PADDING = baseUnit * 4

// partials.
const StyledList = styled.div({
  alignItems: 'center',
  background: theme.colors.smoke,
  borderLeft: `1px solid ${theme.colors.ash}`,
  boxShadow: theme.shadows.normal,
  display: 'flex',
  flexDirection: 'column',
  height: PREVIEWFRAME_HEIGHT(),
  margin: 0,
  maxWidth: PREVIEW_FRAME_WIDTH,
  opacity: 1,
  overflow: 'hidden',
  padding: 0,
  position: 'fixed',
  right: 0,
  top: HEADER_HEIGHT_DESKTOP,
  transition: 'top 100ms ease, height 100ms ease',
  width: '100%',
  willChange: 'top',
  zIndex: 8,
})

const CloseStack = styled.button({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'block',
  padding: baseUnit,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 5,
})

const Preview = styled.div({
  height: '100%',
  minHeight: PREVIEWFRAME_HEIGHT(HEADER_HEIGHT_DESKTOP + FRAME_PADDING),
  maxWidth: '100%',
  overflow: 'auto',
  padding: FRAME_PADDING,
  width: '100%',
  zIndex: 2,
})

const CardWrapper = styled.ul({
  display: 'flex',
  height: 'fit-content',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  transition: 'all 300ms ease',
  width: '100%',
})

const Card = styled.li({
  alignItems: 'flex-start',
  display: 'flex',
  height: 'fit-content',
  margin: 0,
  maxWidth: '100%',
  opacity: 1,
  padding: 0,
  transition: 'all 300ms ease',
  width: '100%',

  '> *': {
    height: '100%',
    '> *': {
      height: '100%',
    },
  },
})

// main component.
const PreviewFrame = ({ items = [], style, onCloseClick }) => {
  const { isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  if (!items.length > 0 || isMobile) return null

  // return content.
  return (
    <StyledList style={style}>
      {items.length > 0 && (
        <>
          <CloseStack onClick={onCloseClick}>
            <Icon name="close" size={24} color="black" />
          </CloseStack>

          <Preview>
            <CardWrapper>
              {items.map((item, i) => (
                <Card key={i}>{item}</Card>
              ))}
            </CardWrapper>
          </Preview>
        </>
      )}
    </StyledList>
  )
}

export default PreviewFrame

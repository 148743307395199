// dependencies.
import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router'
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { format } from 'date-fns'
// components.
import { Breadcrumbs, Tabs } from '../../components/Layout'
import { Button } from '../../components/Button'
import { Drawer, ShowFrame } from '../../components/Modal'
import { Form } from '../../components/Form'
import { Loader } from '../../components/Icon'
import { ReportCard } from '../../components/Card'
import { Stack } from '../../components/List'
import AdminTemplate from '../../templates/AdminPanel'
import BranchSelect from '../../components/Filters/BranchSelect'
import Header from '../../components/Layout/Header'
import FloatingButton from '../../components/Button/MobileButton'
// utils.
import notifyToast from '../../js/notifyToast'
import {
  db,
  getAnomalyById,
  getLastCardNumberByType,
  getLocationById,
  getUserById,
  storage,
} from '../../js/firebase/firebase'
import mailNotificationsMessage from '../../js/mailNotificationMessage'
import { useDimensions } from '../../js/hooks'
import { useAppDispatch, useAppSelector } from '../../js/store/hooks'
// constants.
import { EDIT_COMPLETE_CARD_FORM, EDIT_IN_PROCESS_CARD_FORM, NEW_CARD_FORM } from './constants'
import { IS_DEV } from '../../constants'
import { selectUserData } from '../../js/auth/authSelectors'
import { makeSelectCardsByStatus, selectCardsFilters } from '../../js/cards/cardsSelectors'
import { cardAdded, cardUpdated, reload, setFilters } from '../../js/cards/cardsSlice'
import { selectUsersData } from '../../js/users/usersSelectors'
import {
  selectCardsLocationsData,
  selectLocationsForSelect,
  selectLocationsTree,
} from '../../js/locations/locationsSelectors'
import {
  selectAnomaliesData,
  selectAnomaliesForSelect,
} from '../../js/anomalies/anomaliesSelectors'
import useFetchCardsData from '../../js/cards/hooks/useFetchCardsData'

/***************************/
/*                         */
/*    Admin Home Screen    */
/*                         */
/***************************/

// helpers.
const selectCardType = [
  { label: 'Seguridad', name: 'TS' },
  { label: 'Órden y Limpieza', name: 'TO' },
  { label: 'Mantenimiento', name: 'TM' },
  { label: 'Calidad', name: 'TQ' },
]

const selectPriorities = [
  { label: 'Baja (60d)', name: 0 },
  { label: 'Media baja (30d)', name: 1 },
  { label: 'Media alta (7d)', name: 2 },
  { label: 'Alta (2d)', name: 3 },
]

const buildBreadcrumbs = (array, refId, parents = []) => {
  if (!refId) return null

  for (let i = 0; i < array.length; i++) {
    const obj = array[i]

    if (obj.ref && obj.ref.id === refId) {
      parents.push(obj)
      return parents
    }

    if (Array.isArray(obj.children) && obj.children.length > 0) {
      parents.push(obj)
      const res = buildBreadcrumbs(obj.children, refId, parents)

      if (res) {
        return res
      } else {
        parents.pop()
      }
    }
  }

  return null
}

// tab globales
const Tab = ({ dispatch, items, onFileUpload, filesRef = [], videosRef = [], userData }) => {
  const [activeStack, setActiveStack] = useState('')
  const [stackContent, setStackContent] = useState([{}])
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)

  const tsCards = (items && items.filter(({ type }) => type === 'TS')) || []
  const toCards = (items && items.filter(({ type }) => type === 'TO')) || []
  const tmCards = (items && items.filter(({ type }) => type === 'TM')) || []
  const tqCards = (items && items.filter(({ type }) => type === 'TQ')) || []

  const handleStackClick = (type, stack) => {
    setActiveStack(type)
    setStackContent(stack)
  }

  const handleStackClose = () => {
    setActiveStack('')
    setStackContent([])
  }

  const handleModalOpenFromTab = (open) => {
    setModalOpen(open)
  }

  const updateCardStatus = (cardId) => {
    setStackContent((prevStack) => {
      const updatedStack = prevStack.filter((card) => card.id !== cardId)
      if (updatedStack.length === 0) {
        handleStackClose() // Close the stack if there are no cards left
      }
      return updatedStack
    })
  }

  const handleChangeState = async (obj) => {
    obj.locationId = obj.locationRef?.id
    let location = ''
    if (obj.locationRef) location = await getLocationById(obj.locationRef?.id)
    obj.location = location.name
    obj.cardType =
      obj.type === 'TS'
        ? 'Seguridad'
        : obj.type === 'TO'
          ? 'Órden y Limpieza'
          : obj.type === 'TQ'
            ? 'Calidad'
            : 'Mantenimiento'
    if (obj.anomaliesRefs && obj.anomaliesRefs.length > 0) {
      obj.anomaliesIds = obj.anomaliesRefs?.reduce((result, obj) => {
        result[obj.id] = true
        return result
      }, {})
    }

    if (obj.status === 0) {
      setModalContent({
        title: 'Pasar tarjeta a en proceso',
        cardId: obj.ref.id,
        component: (
          <Form
            items={EDIT_IN_PROCESS_CARD_FORM(obj.anomalies, selectPriorities)}
            defaultValues={obj}
            onClick={(obj) => handleUpdateCardClick(obj)}
          />
        ),
      })
    } else if (obj.status === 1) {
      setModalContent({
        title: 'Cerrar tarjeta',
        cardId: obj.ref.id,
        component: (
          <Form
            items={EDIT_COMPLETE_CARD_FORM(obj.anomalies, selectPriorities)}
            defaultValues={obj}
            onClick={(obj) => handleCompleteCardClick(obj, userData)}
            onFileUpload={onFileUpload}
          />
        ),
      })
    }

    setModalOpen(true)
  }

  const handleUpdateCardClick = async (obj) => {
    try {
      const { ref, proposedImprovement, priority, instalationStatus, executor, extremeDatePlan } =
        obj
      const cardRef = doc(db, 'cards', ref.id)
      const updateFields = {
        proposedImprovement,
        priority,
        instalationStatus,
        executor,
        status: 1,
        extremeDatePlan,
        updatedDate: new Date(),
      }
      const originalCardDoc = await getDoc(cardRef)

      await updateDoc(cardRef, updateFields)
      dispatch(cardUpdated({ id: ref.id, ...originalCardDoc.data(), ...updateFields }))
      notifyToast('Tarjeta actualizada correctamente', 'success')
      updateCardStatus(ref.id)
      setModalOpen(false)
    } catch (error) {
      console.error('Ha Error al actualizar la tarjeta:', error)
      notifyToast('Ha ocurrido un error al actualizar la tarjeta', 'error')
    }
  }

  const handleCompleteCardClick = async (obj, userData) => {
    try {
      const { ref, validationDate, otNumber, improvementMade, comments } = obj
      const userRef = doc(db, 'users', userData.uid)
      const cardRef = doc(db, 'cards', ref.id)
      const updateFields = {
        validationDate,
        otNumber,
        improvementMade,
        status: 2,
        extremeDateReal: new Date(),
        updatedDate: new Date(),
        comments: comments || '',
        closingPersonRef: userRef,
        evidenceFiles: filesRef.current,
        evidenceVideos: videosRef.current,
      }
      const originalCardDoc = await getDoc(cardRef)
      await updateDoc(cardRef, updateFields)
      dispatch(cardUpdated({ id: ref.id, ...originalCardDoc.data(), ...updateFields }))
      notifyToast('Tarjeta completada correctamente', 'success')
      updateCardStatus(ref.id)
      setModalOpen(false)
    } catch (error) {
      console.error('Error al completar la tarjeta:', error)
      notifyToast('Ha ocurrido un error al completar la tarjeta', 'error')
    }
  }

  return (
    <>
      <Stack>
        {tsCards
          .map((item, i) => {
            return (
              <ReportCard
                key={`${item.id}--${i}`}
                type={item.type}
                length={tsCards.length}
                isMinimal
                onClick={() => handleStackClick(item.type, tsCards)}
              />
            )
          })
          .slice(0, 1)}
        {toCards
          .map((item, i) => (
            <ReportCard
              key={`${item.id}--${i}`}
              type={item.type}
              length={toCards.length}
              isMinimal
              onClick={() => handleStackClick(item.type, toCards)}
            />
          ))
          .slice(0, 1)}
        {tmCards
          .map((item, i) => (
            <ReportCard
              key={`${item.id}--${i}`}
              type={item.type}
              length={tmCards.length}
              isMinimal
              onClick={() => handleStackClick(item.type, tmCards)}
            />
          ))
          .slice(0, 1)}
        {tqCards
          .map((item, i) => (
            <ReportCard
              key={`${item.id}--${i}`}
              type={item.type}
              length={tqCards.length}
              isMinimal
              onClick={() => handleStackClick(item.type, tqCards)}
            />
          ))
          .slice(0, 1)}
      </Stack>

      <ShowFrame
        onCloseClick={() => handleStackClose()}
        activeStack={activeStack}
        items={
          stackContent.length > 0 &&
          stackContent.map((item) => {
            return (
              <ReportCard
                key={item.id}
                type={item.type}
                card={item}
                onActionClick={item.status < 3 ? () => handleChangeState(item) : null}
                actionLabel={
                  item.status === 0
                    ? 'Pasar a estado en proceso'
                    : item.status === 1 && 'Cerrar tarjeta'
                }
              />
            )
          })
        }
      />
      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpenFromTab}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

// main component.
const AdminHomeScreen = ({ navMenu, navTitle }) => {
  const params = useParams()
  const scrollRef = useRef(null)
  // const [parentRef, setParentRef] = useState(null)
  // console.log(parentRef)
  // hooks.
  const userData = useAppSelector(selectUserData)

  const anomalies = useAppSelector(selectAnomaliesData)
  const anomaliesSelectOptions = useAppSelector(selectAnomaliesForSelect)
  const { isMobile } = useDimensions()

  // states.
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  // data object.
  const [files, setFiles] = useState([])
  const filesRef = useRef([])

  const [videos, setVideos] = useState([])
  const videosRef = useRef([])
  const dispatch = useAppDispatch()
  const users = useAppSelector(selectUsersData)
  const selectItemData = useAppSelector(selectLocationsTree)
  const locations = useAppSelector(selectCardsLocationsData)
  const selectLocations = useAppSelector(selectLocationsForSelect)

  const selectCardsByStatus = makeSelectCardsByStatus(users, anomalies, locations)
  const cardsByStatus = useAppSelector(selectCardsByStatus)

  const loaded = useFetchCardsData(!params.locationId)
  const cardsFilters = useAppSelector(selectCardsFilters)

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //
  useEffect(() => {
    // Clean the state before navigate to another screen.
    const cleanUp = () => {
      dispatch(reload())
    }

    return cleanUp
  }, [params.locationId, dispatch])

  useEffect(() => {
    filesRef.current = files
  }, [files])

  useEffect(() => {
    videosRef.current = videos
  }, [videos])

  const onFileUpload = (file, isVideo = false) => {
    if (!file) {
      setFiles([])
      return
    }

    return new Promise((resolve, reject) => {
      const currentTime = new Date()
      const fileName = `${format(currentTime, 'yyyyMMddHHmmss')}_${file.name}`
      const path = `cards/${fileName}`

      const storageRef = ref(storage, path)
      const uploadTask = uploadBytesResumable(storageRef, file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          if (isVideo) {
            setVideos([])
          } else {
            setFiles([])
          }
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`Upload is ${progress}% done.`)
        },
        (err) => {
          console.error('onFileUpload: Upload error', err)
          reject(err)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              if (url) {
                if (isVideo) {
                  setVideos([path])
                } else {
                  setFiles([path])
                }
                resolve(url)
              }
            })
            .catch((err) => {
              console.error(err)
              reject(err)
            })
        }
      )
    })
  }

  const deleteFile = (isVideo) => {
    // todo delete file from storage
    if (isVideo) {
      setVideos([])
    } else {
      setFiles([])
    }
  }

  // open modal.
  const handleModalOpen = (open) => {
    filesRef.current = []
    setModalOpen(open)
  }

  // handle add new card modal.
  const handleNewCardModal = () => {
    const obj = {}
    obj.locationId = params.locationId
    setModalContent({
      title: 'Crear tarjeta',
      component: (
        <Form
          items={NEW_CARD_FORM(selectCardType, anomaliesSelectOptions, selectLocations)}
          onClick={(obj) => handleCreateCardClick(obj)}
          onFileUpload={onFileUpload}
          onFileDelete={deleteFile}
          defaultValues={obj}
        />
      ),
    })

    setModalOpen(true)
  }

  const findResponsibleEmail = async (location) => {
    if (!location) {
      return null
    }

    if (location.responsibleRef) {
      const responsible = await getUserById(location.responsibleRef.id)
      if (responsible) {
        if (
          !responsible.data().email ||
          responsible.data().email === `${responsible.data().document}@ccu.uy`
        ) {
          return null
        }
        return [responsible.data().email]
      }
    }

    const parent = await getLocationById(location.parentRef.id)
    return findResponsibleEmail(parent)
  }
  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new card.
  const handleCreateCardClick = async (obj) => {
    const { locationId, anomaliesIds, cardType, ...cardObj } = obj

    if (!anomaliesIds || Object.keys(anomaliesIds).length === 0) {
      notifyToast('Debe seleccionar al menos una anomalía', 'warning')
      return
    }
    const lastNumber = await getLastCardNumberByType(cardObj.type)
    cardObj.number = lastNumber + 1
    cardObj.anomaliesRefs = []
    cardObj.plantNumber = 2501
    if (anomaliesIds) {
      const anomalyIds = Object.keys(anomaliesIds).filter((anomalyId) => anomaliesIds[anomalyId])

      for (const anomalyId of anomalyIds) {
        const anomaly = await getAnomalyById(anomalyId)
        cardObj.anomaliesRefs.push(anomaly.ref)
      }
    }

    const location = locationId ? await getLocationById(locationId) : null

    if (location) {
      cardObj.locationRef = location.ref
      cardObj.responsibleRef = location.responsibleRef
    } else {
      cardObj.locationRef = null
      cardObj.responsibleRef = null
    }
    const userRef = doc(db, 'users', userData.uid)

    cardObj.createdDate = new Date()
    cardObj.updatedDate = new Date()
    cardObj.status = 0
    cardObj.createdByRef = userRef
    cardObj.files = filesRef.current
    cardObj.videos = videosRef.current

    const paddedNumber = cardObj.number.toString().padStart(9, '0')
    const uid = cardObj.type + cardObj.plantNumber + paddedNumber
    const cardRef = doc(db, 'cards', uid)

    await setDoc(cardRef, { ...cardObj, ref: cardRef })
      .then(async () => {
        const newCardDoc = await getDoc(cardRef)
        const newCard = { id: newCardDoc.id, ...newCardDoc.data() }
        dispatch(cardAdded(newCard))
        notifyToast('Tarjeta solicitada correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    const message = mailNotificationsMessage(
      cardObj.type,
      cardObj.description,
      cardObj.createdDate,
      `${userData.firstname} ${userData.lastname}`
    )

    const responsibleEmail = await findResponsibleEmail(location)

    let receipments = []
    let customReceipments = []
    if (cardObj.type === 'TS') {
      // seguridad
      customReceipments = IS_DEV ? ['sebastiancura97@gmail.com'] : ['jucontrer@ccu.uy']
    } else if (cardObj.type === 'TO') {
      // orden y limpieza
      customReceipments = IS_DEV ? ['sebastiancura97@gmail.com'] : ['jucontrer@ccu.uy']
    } else if (cardObj.type === 'TM') {
      // mantenimiento
      customReceipments = IS_DEV
        ? ['sebastiancura97@gmail.com']
        : ['jucontrer@ccu.uy', 'rlugon@ccu.uy']
    } else if (cardObj.type === 'TQ') {
      // calidad
      customReceipments = IS_DEV
        ? ['sebastiancura97@gmail.com']
        : ['fhenriquez@ccu.uy', 'mbobadfe@ccu.uy', 'lofiorava@ccu.uy']
    }
    receipments = customReceipments.concat(responsibleEmail)

    const mailNotificationObj = {
      message,
      to: receipments,
    }

    await addDoc(collection(db, 'mailNotifications'), mailNotificationObj)

    setModalOpen(false)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  useEffect(() => {
    dispatch(setFilters({ locationId: params.locationId }))
  }, [params.locationId])

  useEffect(() => {
    setBreadcrumbs(buildBreadcrumbs(selectItemData, cardsFilters.locationId))
  }, [selectItemData, cardsFilters])

  // tabs content.
  const tabLabels = [
    {
      label: `Pendientes (${cardsByStatus.pending?.length ?? 0})`,
      component:
        loaded === 'succeeded' ? (
          <Tab
            dispatch={dispatch}
            items={cardsByStatus.pending}
            onFileUpload={onFileUpload}
            filesRef={filesRef}
            videosRef={videosRef}
          />
        ) : (
          <Loader />
        ),
    },
    {
      label: `En proceso (${cardsByStatus.inProgress?.length ?? 0})`,
      component:
        loaded === 'succeeded' ? (
          <Tab
            dispatch={dispatch}
            items={cardsByStatus.inProgress}
            onFileUpload={onFileUpload}
            filesRef={filesRef}
            videosRef={videosRef}
            userData={userData}
          />
        ) : (
          <Loader />
        ),
    },
    {
      label: `Cerradas (${cardsByStatus.done?.length >= 99 ? '99+' : cardsByStatus.done?.length ?? 0})`,
      component:
        loaded === 'succeeded' ? (
          <Tab
            dispatch={dispatch}
            items={cardsByStatus.done}
            onFileUpload={onFileUpload}
            filesRef={filesRef}
            videosRef={videosRef}
            userData={userData}
          />
        ) : (
          <Loader />
        ),
    },
  ]

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        {loaded === 'succeeded' ? (
          <>
            <Header justifyContent="space-between">
              <BranchSelect placeholder="Buscar ubicaciones" items={selectItemData} />
              {isMobile ? (
                <FloatingButton onClick={() => handleNewCardModal()} />
              ) : (
                <Button onClick={() => handleNewCardModal()}>Nueva Tarjeta</Button>
              )}
            </Header>
            <Breadcrumbs pathname="/admin" items={breadcrumbs} />
            <Tabs items={tabLabels} ref={scrollRef} />
          </>
        ) : (
          <Loader />
        )}
      </AdminTemplate>
      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default AdminHomeScreen

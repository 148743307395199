// global.
export const IS_DEV = process.env.NODE_ENV === 'development'
export const PUBLIC = process.env.PUBLIC_URL

export const DATE_FORMAT = 'DD/MM/YYYY'
export const US_DATE_FORMAT = 'yyyy-MM-DD'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm'
export const MONTHS_ES =
  'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'
export const MONTHS_SHORT_ES = 'Ene._Feb._Mar._Abr._May._Jun._Jul._Ago._Set._Oct._Nov._Dec.'
export const DAYS_ES = 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'
export const DAYS_SHORT_ES = 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'
export const DAYS_MIN_ES = 'Do_Lu_Ma_Mi_Ju_Vi_Sa'

export const CURRENCY = 'UYU'

export const GMAPS_API_KEY = 'AIzaSyCetgQOan-haj8KxNX7TSTaiYqJHUrD1vc'
export const GMAP_LIBRARIES = ['drawing', 'geometry']

export const DOMAIN = 'ccu.com.uy'
export const SITE_URL = `https://${DOMAIN}`

// social.
export const TWITTER_USERNAME = 'ccuuy'

const auditorItems = [
  { type: 'blockspacer', label: 'STOP', icon: 'bookmark' },
  { label: 'Mis auditorías', icon: 'home', path: '/auditorias/stop' },
]

const auditorPrivateItems = [
  { label: 'Asignaciones', icon: 'user', path: '/auditorias/stop/asignaciones' },
  { label: 'Auditorías', icon: 'title', path: '/auditorias/stop/auditorias' },
  { label: 'Reportes', icon: 'chart', path: '/auditorias/stop/reportes' },
]

// navigation.
export const NAVIGATION_ITEMS = (isAuditor = false) => {
  return {
    superadmin: {
      title: 'Super Admin',
      items: [
        { type: 'blockspacer', label: 'Global', icon: 'bookmark' },
        { label: 'Inicio', icon: 'home', path: '/admin', withLocation: true },
        { label: 'Tarjetas', icon: 'title', path: '/admin/tarjetas' },
        { label: 'Reportes', icon: 'chart', path: '/admin/reportes' },
        { label: 'Presupuestos', icon: 'wallet', path: '/admin/presupuestos' }, // only superadmin.
        { label: 'Anomalías', icon: 'warning', path: '/admin/anomalias' }, // only superadmin.
        { label: 'Ubicaciones', icon: 'location', path: '/admin/ubicaciones' }, // only superadmin.
        { label: 'Usuarios', icon: 'user', path: '/admin/usuarios' },
        { type: 'blockspacer', label: 'STOP', icon: 'bookmark' },
        ...(isAuditor ? [{ label: 'Mis auditorías', icon: 'home', path: '/auditorias/stop' }] : []),
        ...auditorPrivateItems,
        { label: 'Ubicaciones', icon: 'location', path: '/auditorias/stop/ubicaciones' }, // only superadmin.
        { label: 'Auditores', icon: 'user', path: '/auditorias/stop/auditores' }, // only superadmin.
        { label: 'Configuración', icon: 'settings', path: '/auditorias/stop/configuracion' }, // only superadmin.
        { type: 'spacer' },
        { label: 'Ajustes', icon: 'settings', path: '/admin/ajustes' },
      ],
    },
    admin: {
      title: 'Admin',
      items: [
        { label: 'Inicio', icon: 'home', path: '/admin', withLocation: true },
        { label: 'Tarjetas', icon: 'title', path: '/admin/tarjetas' },
        { label: 'Reportes', icon: 'chart', path: '/admin/reportes' },
        { label: 'Usuarios', icon: 'user', path: '/admin/usuarios' },
        ...(isAuditor ? auditorItems : []),
        ...(isAuditor ? auditorPrivateItems : []),
        { type: 'spacer' },
        { label: 'Ajustes', icon: 'settings', path: '/admin/ajustes' },
      ],
    },
    operator: {
      title: 'Funcionario',
      items: [
        { label: 'Inicio', icon: 'home', path: '/funcionario', withLocation: true },
        ...(isAuditor ? auditorItems : []),
        { type: 'spacer' },
        { label: 'Ajustes', icon: 'settings', path: '/funcionario/ajustes' },
      ],
    },
  }
}

export const DEV_NAVIGATION_ITEMS = {
  title: 'Developer',
  items: [
    // TOTO: dev-users screen is not working right now.
    // { type: 'blockspacer', label: 'Developer', icon: 'bookmark' },
    // { label: 'Usuarios', icon: 'home', path: '/developer/usuarios' },
    ...NAVIGATION_ITEMS(true).superadmin.items,
  ],
}

// flags.
export const SIGN_UP_ENABLED = true

// dimensions.
export const NAVIGATION_WIDTH = 168
export const NAVIGATION_CONDENSED_WIDTH = 56
export const FILTERS_ASIDE_WIDTH = 320
export const HEADER_HEIGHT_DESKTOP = 72
export const HEADER_HEIGHT_MOBILE = 56
export const BOTTOMBAR_HEIGHT = 48
export const TABLE_HEADER_HEIGHT = 32
export const AUDITORIES_TABLE_HEADER_HEIGHT = 128
export const PREVIEW_FRAME_WIDTH = 592

// dependencies.
import { useEffect, useState } from 'react'
// components.
import AdminTemplate from '../../../templates/AdminPanel'
import Header from '../../../components/Layout/Header'
// utils.
import { Loader } from '../../../components/Icon'
import { useDimensions } from '../../../js/hooks'
// constants.
import { useAppDispatch, useAppSelector } from '../../../js/store/hooks'

import {
  AuditAssignmentsDesktopItem,
  AuditAssignmentsDesktopSort,
  AuditAssignmentsDesktopWrapper,
  AuditAssignmentsMobileItem,
  AuditAssignmentsMobileWrapper,
} from '../../../components/screens/AuditAssignments'
import { clearFilters, setFilters } from '../../../js/audits/auditsSlice'
import { selectFilteredAudits } from '../../../js/audits/auditsSelectors'
import { selectUsersData } from '../../../js/users/usersSelectors'
import { selectAuditsLocationsData } from '../../../js/locations/locationsSelectors'
import SearchAuditsByAuditor from '../../../components/screens/AuditAssignments/SearchAuditsByAuditor'
import useFetchAuditsData from '../../../js/cards/hooks/useFetchAuditsData'
import FilterTimeRange from '../../../components/Filters/TimeRange'
import ToggleFiltersButton from '../../../components/Filters/ToggleFilterAsideButton'

/************************************/
/*                                  */
/*    Auditor Assignments Screen    */
/*                                  */
/************************************/

// main component.
const StopAuditAssignmentsScreen = ({ navMenu, navTitle }) => {
  // hooks.
  const dispatch = useAppDispatch()
  const { isMobile } = useDimensions()
  const loaded = useFetchAuditsData()
  // states
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  // data.
  const locations = useAppSelector(selectAuditsLocationsData)
  const users = useAppSelector(selectUsersData)
  const { all: auditorsAssignments } = useAppSelector(selectFilteredAudits([], users, locations))

  useEffect(() => {
    dispatch(clearFilters())
  }, [])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        isMobile={isMobile}
        modalOpen={modalOpen}
        sectionTitle={navTitle}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        filterComponents={[
          <SearchAuditsByAuditor
            key="in-audits-responsible"
            onSearch={(term) => {
              dispatch(setFilters({ searchTerm: term }))
            }}
          />,
          <FilterTimeRange
            key="in-audits-time-range"
            granularity={['m', 'y']}
            onFilter={({ fromDate, toDate }) => {
              dispatch(setFilters({ fromDate, toDate }))
            }}
          />,
        ]}
        onOverlayClick={() => setModalOpen(false)}
      >
        <Header justifyContent="space-between">
          {isMobile ? (
            <>
              <ToggleFiltersButton
                isActive={filtersOpen}
                onChange={(isOpen) => setFiltersOpen(isOpen)}
              />
            </>
          ) : (
            <>
              <FilterTimeRange
                granularity={['m', 'y']}
                onFilter={({ fromDate, toDate }) => {
                  dispatch(setFilters({ fromDate, toDate }))
                }}
              />
              ,
              <SearchAuditsByAuditor
                onSearch={(term) => {
                  dispatch(setFilters({ searchTerm: term }))
                }}
              />
            </>
          )}
        </Header>
        {loaded === 'succeeded' ? (
          isMobile ? (
            <AuditAssignmentsMobileWrapper
              isEmpty={!auditorsAssignments && !auditorsAssignments.length}
            >
              {auditorsAssignments.map((audit, i) => (
                <AuditAssignmentsMobileItem key={audit.id} item={audit} isEven={i % 2 === 0} />
              ))}
            </AuditAssignmentsMobileWrapper>
          ) : (
            <AuditAssignmentsDesktopWrapper
              isEmpty={!!auditorsAssignments && !auditorsAssignments.length}
            >
              <AuditAssignmentsDesktopSort
                items={auditorsAssignments}
                onSort={(e) => dispatch(setFilters({ sort: e }))}
              />
              {auditorsAssignments.map((audit, i) => (
                <AuditAssignmentsDesktopItem key={audit.id} item={audit} isEven={i % 2 === 0} />
              ))}
            </AuditAssignmentsDesktopWrapper>
          )
        ) : (
          <Loader />
        )}
      </AdminTemplate>
    </>
  )
}

export default StopAuditAssignmentsScreen

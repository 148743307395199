// dependencies.
import { forwardRef } from 'react'
import styled from 'styled-components'
// utils.
import { constants, theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'

/**********************************/
/*                                */
/*    Screen Wrapper Component    */
/*                                */
/**********************************/

// helpers.
const { WRAPPER_MAXWIDTH } = constants

// partials.
const Overlay = styled.div({
  background: theme.opacity.black50,
  bottom: 0,
  display: 'flex',
  flexGrow: 1,
  left: 0,
  margin: 0,
  minHeight: '100%',
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 10,
})

const Background = styled.div(
  {
    alignItems: 'center',
    background: (props) => props.background,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 0,
    height: (props) => `${props.height}px`,
    overflow: (props) => (props.modalOpen ? 'hidden' : 'auto'),
    pointerEvents: (props) => (props.modalOpen ? 'none' : 'all'),
    width: '100%',
  },
  `@media print {
    background-color: ${theme.colors.white};
    height: 100%;
    margin: 0 auto;
  }`
)

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  maxWidth: WRAPPER_MAXWIDTH,
  width: '100%',
})

// main component.
const ScreenWrapper = ({ modalOpen, background = theme.admin.admin, children, onClick }, ref) => {
  // hooks.
  const { height } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <>
      {modalOpen && <Overlay onClick={onClick} />}
      <Background ref={ref} background={background} height={height} modalOpen={modalOpen}>
        <Container>{children}</Container>
      </Background>
    </>
  )
}

export default forwardRef(ScreenWrapper)

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { NAVIGATION_ITEMS } from '../../constants'

// handle redirect depending on user access.
function useAccessRedirect(userData) {
  const [activePath, setActivePath] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    const urlObject = new URL(window.location.href)

    const allowedRoutes =
      NAVIGATION_ITEMS(userData.isAuditor)
        [userData.access]?.items.map((item) => item.path)
        .filter(Boolean) || []

    const dynamicRoutes =
      NAVIGATION_ITEMS(userData.isAuditor)
        [userData.access]?.items.filter((item) => item.withLocation)
        .map(
          (item) =>
            urlObject?.pathname.replace(`${item.path}/`, '').length > 18 && urlObject?.pathname
        )
        .filter(Boolean) || []

    allowedRoutes.push(...dynamicRoutes)
    if (typeof window === 'undefined') return

    setActivePath(urlObject?.pathname)

    if (userData.access !== 'developer' && !allowedRoutes.includes(urlObject?.pathname)) {
      navigate(allowedRoutes[0] || '/')
    }
  }, [userData])

  return activePath
}

export default useAccessRedirect

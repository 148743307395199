import { createSelector } from 'reselect'
import { getNameFull } from '../usernameUtils'

export const selectAuditsLocationsData = (state) =>
  state.locations.data.filter((locationObj) => {
    return locationObj.level <= 3
  })

export const selectCardsLocationsData = (state) =>
  state.locations.data.filter((locationObj) => {
    // filter only the locations has not 'isAuditable' property or it's false
    return !locationObj.isAuditable
  })
// Selector for locations select options
export const selectLocationsForSelect = createSelector([selectCardsLocationsData], (locations) =>
  locations.map((location) => ({
    label: location.name,
    name: location.id,
  }))
)

export const selectLocationsForAuditsSelect = createSelector(
  [selectAuditsLocationsData],
  (locations) => {
    const auditsLocations = locations.filter((location) => location.isAuditable === true)
    return auditsLocations.map((location) => {
      if (!location.parentRef) {
        return {
          label: location.name,
          name: location.id,
        }
      }
      const parent = locations.filter((loc) => loc.id === location.parentRef?.id)
      if (!parent || parent.length === 0) {
        return {
          label: location.name,
          name: location.id,
        }
      }
      return {
        label: `${parent[0].name} - ${location.name}`,
        name: location.id,
      }
    })
  }
)
export const selectLocationsForWorkstationSelect = createSelector(
  [selectCardsLocationsData],
  (locations) => {
    const locationsSelect = locations.filter((location) => location.level <= 2)

    return locationsSelect
      .map((location) => {
        if (!location.parentRef) {
          return {
            label: location.name,
            name: location.id,
            parentName: '',
          }
        }
        const parent = locations.find((loc) => loc.id === location.parentRef?.id)
        if (!parent) {
          return {
            label: location.name,
            name: location.id,
            parentName: '',
          }
        }
        return {
          label: `${parent.name} - ${location.name}`,
          name: location.id,
          parentName: parent.name, // Guardamos el nombre del padre
        }
      })
      .sort((a, b) => {
        if (!a.parentName || !b.parentName) {
          return a.label.localeCompare(b.label)
        }
        return a.parentName.localeCompare(b.parentName)
      })
  }
)

export const selectLocationsTree = createSelector([selectCardsLocationsData], (locations) => {
  const hierarchy = []
  const locationMap = {}

  // Crear una copia mutable de las ubicaciones para poder añadir hijos
  const mutableLocations = locations.map((location) => ({ ...location, children: [] }))

  // Mapear las ubicaciones por su referencia en un objeto mutable
  mutableLocations.forEach((location) => {
    locationMap[location.id] = location
  })

  // Construir la jerarquía
  mutableLocations.forEach((location) => {
    if (location.parentRef) {
      const parent = locationMap[location.parentRef.id]
      if (parent) {
        parent.children.push(location)
      }
    } else {
      hierarchy.push(location)
    }
  })

  return hierarchy
})

export const selectAuditLocationsTree = createSelector([selectAuditsLocationsData], (locations) => {
  const hierarchy = []
  const locationMap = {}

  const mutableLocations = locations.map((location) => ({ ...location, children: [] }))

  mutableLocations.forEach((location) => {
    locationMap[location.id] = location
  })

  mutableLocations.forEach((location) => {
    if (location.parentRef) {
      const parent = locationMap[location.parentRef.id]
      if (parent) {
        parent.children.push(location)
      }
    } else {
      hierarchy.push(location)
    }
  })

  // Recursive function to filter the hierarchy and remove non-auditable nodes
  const filterHierarchy = (node) => {
    node.children = node.children.map(filterHierarchy).filter((child) => child !== null) // remove non-auditable children

    // If node is level 3 and is auditable, keep it
    if (node.level === 3) {
      return node.isAuditable ? node : null
    }

    return node.children.length > 0 ? node : null
  }

  return hierarchy
    .map(filterHierarchy) // Aplicar la función a los nodos raíz
    .filter((node) => node !== null) // Eliminar nodos no válidos
})

export const makeSelectLocationsForTable = (parentRef, users) =>
  createSelector(
    [selectCardsLocationsData], // Ensure this is correctly selecting the raw locations data from state
    (locations) => {
      const currentLocations = locations.filter((locationObj) => {
        if (parentRef === null) {
          return locationObj.parentRef === undefined
        }
        return locationObj.parentRef?.id === parentRef.id
      })
      return currentLocations.map((locationObj) => {
        const { id, name, responsibleRef } = locationObj

        const href = '/admin/ubicaciones/' + id
        const user = users.filter((user) => user.uid === responsibleRef?.id)
        // table items
        return {
          items: [
            {
              copy: name,
              grow: 2,
              to: href,
              icon: 'title',
            },
            {
              copy: user.length > 0 ? getNameFull(user[0].firstname, user[0].lastname) : '',
              grow: 2,
              label: 'Responsable:',
              icon: 'view',
            },
          ],
          disableEdit: false,
          raw: locationObj,
        }
      })
    }
  )
export const makeSelectLocationsForAuditsTable = () =>
  createSelector([selectAuditsLocationsData], (locations) => {
    const auditsLocations = locations.filter((locationObj) => {
      return locationObj.level === 3 && locationObj.isAuditable
    })

    const mappedLocations = auditsLocations.map((locationObj) => {
      let subarea = []
      let area = []

      if (locationObj.parentRef) {
        subarea = locations.filter((location) => location.id === locationObj.parentRef?.id)
      }
      if (subarea.length > 0 && subarea[0].parentRef) {
        area = locations.filter((location) => location.id === subarea[0].parentRef?.id)
      }

      return {
        areaName: area.length > 0 ? area[0].name : '',
        subareaName: subarea.length > 0 ? subarea[0].name : '',
        items: [
          {
            copy: locationObj.name,
            grow: 2,
            icon: 'title',
          },
          {
            copy: area.length > 0 ? area[0].name : '',
            grow: 2,
            label: 'Área:',
            icon: 'view',
          },
          {
            copy: subarea.length > 0 ? subarea[0].name : '',
            grow: 2,
            label: 'Subárea:',
            icon: 'view',
          },
        ],
        disableEdit: false,
        raw: locationObj,
      }
    })

    // Ordenar por área y luego por subárea
    mappedLocations.sort((a, b) => {
      if (a.areaName < b.areaName) return -1
      if (a.areaName > b.areaName) return 1
      if (a.subareaName < b.subareaName) return -1
      if (a.subareaName > b.subareaName) return 1
      return 0
    })

    return mappedLocations
  })

export const makeSelectLocationsByParentRef = (parentRef) =>
  createSelector([(state) => state.locations.list], (locations) =>
    locations.filter((location) => {
      if (parentRef === null) {
        return !location.parentRef
      }
      return location.parentRef?.id === parentRef
    })
  )

export const selectLocationsStatus = (state) => state.locations.status

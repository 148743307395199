import moment from 'moment'
import { getNameFull } from './usernameUtils'

export const processAuditsData = (
  users,
  locations,
  audits,
  auditItems = [],
  persons = [],
  epps = []
) => {
  return audits.map((auditObj) => {
    const auditor = users.find((user) => user.id === (auditObj.auditorRef?.id || null)) || {}
    const location = locations?.find((loc) => loc.id === auditObj.locationRef?.id) || {}
    const locatationP = location.parentRef
      ? locations.find((loc) => loc.id === location.parentRef.id)
      : null
    const feedback =
      auditObj.auditItems?.length > 0 && auditObj.auditItems.some(({ value }) => value === 2)
        ? 2
        : 1
    const personObj = auditObj.personRef && persons.find((p) => p.id === auditObj.personRef.id)
    const eppObj =
      auditObj.auditItemEpp &&
      auditObj.auditItemEpp.ref &&
      epps.find((epp) => epp.id === auditObj.auditItemEpp?.ref.id)
    const items =
      auditObj.auditItems?.map((item) => {
        const auditItem = auditItems.find((auditItem) => auditItem?.id === item?.ref?.id)
        return {
          ...item,
          name: auditItem?.name || '',
        }
      }) || []

    return {
      ...auditObj,
      id: auditObj.id,
      auditor: getNameFull(auditor.firstname, auditor.lastname),
      location: location.name || '',
      locationP: locatationP ? locatationP.name : '',
      status: auditObj.status || 0,
      deadline: auditObj.deadline || null,
      feedback: auditObj.status > 0 ? feedback : null,
      type: auditObj.type || null,
      comments: eppObj ? `Falta EPP: ${eppObj.name} / ${auditObj.comments}` : auditObj.comments,
      auditItems: items,
      person: personObj?.name || '',
      epp: eppObj?.name || '',
      isContractor: personObj?.type === 'EXT' ? 'Contratista' : '',
    }
  })
}

export const auditFilterByDates = (array, fromDate, toDate) => {
  if (!array) return []

  return array.filter((audit) => {
    if (!audit.completedDate || !audit.completedDate.seconds) {
      return false
    }

    const completedDate = moment(audit.completedDate.seconds * 1000)

    return (
      completedDate.isSameOrAfter(moment(fromDate).startOf('day')) &&
      completedDate.isSameOrBefore(moment(toDate).endOf('day'))
    )
  })
}

export const auditsFilterByAuditor = (array, auditorId) => {
  if (!array) return
  return array
    .filter((item) => {
      if (auditorId === item?.auditorRef?.id) return item
      return null
    })
    .filter((el) => el !== null)
}

export const auditsFilterByPerson = (array, personId) => {
  if (!array) return
  return array
    .filter((item) => {
      if (personId === item?.personRef?.id) return item
      return null
    })
    .filter((el) => el !== null)
}

export const auditsFilterByIsContractor = (array, isContractor) => {
  if (!array || !isContractor) return
  return array
    .filter((item) => {
      const all = isContractor === 'all'
      const ext = isContractor === 'ext'
      const int = isContractor === 'int'
      if (all) return item
      if (ext && item?.isContractor === 'Contratista') return item
      if (int && item?.isContractor === '') return item
      return null
    })
    .filter((el) => el !== null)
}

export const auditsFilterByLocation = (array, location) => {
  if (!array) return []
  return array
    .filter((item) => {
      if (location === item?.locationRef?.id) return item
      return null
    })
    .filter((el) => el !== null)
}

// dependencies.
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Body, Headline } from '../../Typography'
import { Button } from '../../Button'
import { Checkbox, Input } from '../../Form'
import SingleOptionGroup from '../../Form/SingleOptionGroup'
import { useDimensions } from '../../../js/hooks'
import { validateCi } from '../../../js/validateInput'
import { theme } from '../../../styleguide'

/************************/
/*                      */
/*    Form Component    */
/*                      */
/************************/

// helpers.
const getRequiredFields = (fields) => {
  return fields.reduce((acc, field) => {
    if (field.required && field.name) {
      acc.push(field.name)
    }
    if (field.items) {
      const subFields = field.items.flat()
      acc.push(...getRequiredFields(subFields))
    }
    return acc
  }, [])
}

const findValueByKey = (obj, key) => {
  for (const prop in obj) {
    if (prop === key && obj[prop] !== null) {
      return obj[prop]
    } else if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      for (const prop2 in obj[prop]) {
        if (prop2 === key && obj[prop][prop2] !== null) {
          return obj[prop][prop2]
        }
      }
    }
  }
  return null
}

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: (props) => (props.isMobile ? '8px' : '16px'),
  height: (props) => props.height || 'unset',
  margin: '0 auto',
  padding: (props) => (props.isMobile ? '0 16px' : '0 32px'),
  width: '100%',
})

const InputGroup = styled.div({
  display: 'flex',
  gap: (props) => (props.isMobile ? '4px 8px' : '4px 16px'),
  flexWrap: (props) => (props.isMobile ? 'wrap' : 'nowrap'),
  margin: 0,
  width: '100%',
})

const SectionLabel = styled(Headline)({
  flexGrow: 0,
  height: 'fit-content',
  margin: (props) => (props.isMobile ? '16px 0 0' : '24px 0 8px'),
})

const SubmitButton = styled(Button)({
  margin: '64px 0 8px',
})

const SectionHeadline = styled.div({
  alignItems: 'center',
  display: 'flex',
  minHeight: 48,
  gap: 4,
})

const SectionValueTitle = styled(Body).attrs({
  size: 'smaller',
  weight: 'bold',
})({
  flexGrow: 1,
})

const SectionValueExample = styled(Body).attrs({
  color: 'white',
  type: 'caption',
})({
  background: (props) => theme.colors[props.bg] || theme.colors.grey,
  borderRadius: 4,
  padding: '4px 8px',
})

const StyledLabel = styled(Body).attrs({
  size: 'smaller',
  setContent: true,
  nowrap: true,
})({
  alignSelf: 'center',
  flexGrow: 1,
  flexShrink: 0,
  height: 'fit-content',
  width: 'fit-content',
})

const FormInput = ({ item, defaultValue, isMobile, onChange }) => {
  const { disabled, required, label, name, options, placeholder, type, hidden } = item
  const commonProps = { defaultValue, disabled, required, name, onChange, hidden }

  if (type === 'label') {
    return <StyledLabel>{label}</StyledLabel>
  }
  if (type === 'headline') {
    return <SectionLabel isMobile={isMobile}>{label}</SectionLabel>
  }
  if (type === 'checkbox') {
    return <Checkbox {...commonProps} label={placeholder} />
  }
  if (type === 'radioGroup') {
    return <SingleOptionGroup label={placeholder} options={options} {...commonProps} />
  }
  if (type === 'safetyItemsHeadline') {
    return (
      <SectionHeadline isMobile={isMobile}>
        <SectionValueTitle>{label}</SectionValueTitle>
        <SectionValueExample bg="success">Seguro</SectionValueExample>
        <SectionValueExample bg="alert">Inseguro</SectionValueExample>
        <SectionValueExample bg="warning">No aplica</SectionValueExample>
      </SectionHeadline>
    )
  }
  return <Input {...commonProps} options={options} placeholder={placeholder} type={type} />
}

// main component.
const Form = ({ items, height, className, defaultValues, submitLabel = 'Guardar', onClick }) => {
  // hooks.
  const { isMobile } = useDimensions()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // states.
  const [values, setValues] = useState(() => {
    const initialValues = items.reduce((initialEmptyValues, item) => {
      // construct an object with all keys from items with null values.
      if (item.name) initialEmptyValues[item.name] = null

      // if it's an array field, initialize each sub-item's keys with null values.
      if (item.type === 'array') {
        initialEmptyValues[item.name] = {}
        item.items.forEach((subItem) => {
          if (item.name && subItem.name) initialEmptyValues[item.name][subItem.name] = null
          if (subItem.length > 1) {
            subItem.forEach((subSubItem) => {
              if (item.name && subSubItem.name)
                initialEmptyValues[item.name][subSubItem.name] = null
            })
          }
        })
      }

      return initialEmptyValues
    }, {})

    return defaultValues ?? initialValues
  })
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [cardType, setCardType] = useState(null)

  const handleSubmit = async () => {
    if (isSubmitting) {
      return // Prevent multiples submissions.
    }

    setIsSubmitting(true)
    try {
      await onClick(values)
    } catch (error) {
      console.error('Error al enviar el formulario:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle form data update.
  const handleInputChange = (item, value, index, parent = null) => {
    if (item.name === 'eppValue') {
      // find eppRef item from items.
      const eppArray = items.find((group) => group.items?.some((i) => i.name === 'eppRef'))

      if (eppArray) {
        const eppRef = eppArray.items.find((i) => i.name === 'eppRef')
        if (eppRef) {
          // enable eppRef select if value is 2.
          if (value === 2) {
            eppRef.disabled = false
            eppRef.required = true
          } else {
            eppRef.required = false
            eppRef.disabled = true
            setValues({ ...values, eppRef: null, eppValue: value })
            return
          }
        }
      }
    }
    if (item.type === 'radioGroup' && !!parent) {
      const parentKey = parent
      const radioGroupValues = values[parentKey] || []

      // Buscar si ya existe un objeto para este `ref`
      const existingIndex = radioGroupValues.findIndex((r) => r.ref === item.name)
      const updatedRadioGroupValues =
        existingIndex >= 0
          ? radioGroupValues.map((r, i) =>
              i === existingIndex ? { ref: item.name, value: value || item.defaultValue } : r
            )
          : [...radioGroupValues, { ref: item.name, value: value || item.defaultValue }]

      setValues({ ...values, [parentKey]: updatedRadioGroupValues })
    } else if (item.name === 'fullname' && !!item.options) {
      if (value) {
        const selected = items[index].options.filter((option) => option.label === value)
        const updatedValues = { ...values, ...selected[0], fullname: selected[0]?.label }

        setValues(updatedValues)
      }
    } else if (item.name === 'cardType') {
      if (value) {
        if (items[index].options) {
          const cardType = items[index].options.filter((b) => b.name === value)
          if (cardType.length > 0) {
            setCardType(cardType[0].name)
            const updatedValues = { ...values, type: cardType[0].name }
            setValues(updatedValues)
          }
        } else {
          setValues(value)
        }
      }
    } else {
      const buildValue =
        parent && values && parent in values
          ? { [parent]: { ...values[parent], [item.name || index]: value } }
          : parent
            ? { [parent]: {} }
            : { [item.name || index]: value }

      setValues({ ...values, ...buildValue })
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // check required items.
  useEffect(() => {
    if (!items) return

    const requiredFields = getRequiredFields(items)
    const checkValue = requiredFields.every((key) => findValueByKey(values, key))
    let checkDocument = true
    const document = findValueByKey(values, 'document')
    if (document) {
      checkDocument = validateCi(document)
    }
    setDisableSubmit(!checkValue || !checkDocument)
  }, [items, values])

  // only show if there's items.
  if (!items) return null

  // return content.
  return (
    <Wrapper isMobile={isMobile} height={height} className={className}>
      {items.map((item, i) => {
        return item.type === 'array' ? (
          <InputGroup key={i} isMobile={isMobile}>
            {item.items.length > 0 &&
              item.items.map((subItem, j) => {
                if (subItem.length > 1) {
                  return (
                    <InputGroup key={j} isMobile={isMobile}>
                      {subItem.map((groupItem, k) => (
                        <FormInput
                          key={k}
                          item={groupItem}
                          defaultValue={
                            groupItem.defaultValue ||
                            (values &&
                              item.name in values &&
                              groupItem.name in values[item.name] &&
                              values[item.name][groupItem.name]) ||
                            ''
                          }
                          isMobile={isMobile}
                          onChange={(value) => handleInputChange(groupItem, value, k, item.name)}
                          cardType={groupItem.type === 'anomaly' ? cardType : null}
                        />
                      ))}
                    </InputGroup>
                  )
                }

                return (
                  <FormInput
                    key={j}
                    item={subItem}
                    defaultValue={
                      subItem.defaultValue ||
                      (values && subItem.name in values && values[subItem.name]) ||
                      ''
                    }
                    isMobile={isMobile}
                    onChange={(value) => handleInputChange(subItem, value, j, item.name)}
                  />
                )
              })}
          </InputGroup>
        ) : (
          <FormInput
            key={i}
            item={item}
            defaultValue={
              item.defaultValue || (values && item.name in values && values[item.name]) || ''
            }
            isMobile={isMobile}
            onChange={(value) => handleInputChange(item, value, i, item.parent)}
          />
        )
      })}
      <SubmitButton disabled={disableSubmit || isSubmitting} onClick={handleSubmit}>
        {isSubmitting ? 'Enviando...' : submitLabel}
      </SubmitButton>
    </Wrapper>
  )
}

export default Form

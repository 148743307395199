// dependencies.
import { useEffect, useRef, useState } from 'react'
// components.
import AdminTemplate from '../../../templates/AdminPanel'
import {
  ChartsContainer,
  ChartCard,
  AuditorDeadlinesTable,
} from '../../../components/screens/Reports'
import Header from '../../../components/Layout/Header'
import { FilterList } from '../../../components/Typography/FilterChip'
import { Loader } from '../../../components/Icon'
import ToggleFilterButton from '../../../components/Filters/ToggleFilterAsideButton'
import FilterLocation from '../../../components/Filters/Location'
import FilterResponsible from '../../../components/Filters/Responsible'
import FilterPerson from '../../../components/Filters/Client'
import Search from '../../../components/Filters/Search'
import Export from '../../../components/Filters/export/Audits-stop'
// utils.
import { useAppDispatch, useAppSelector } from '../../../js/store/hooks'
import { selectUsersData } from '../../../js/users/usersSelectors'
import { selectAuditLocationsTree, selectAuditsLocationsData } from '../../../js/locations/locationsSelectors'
import { useDimensions } from '../../../js/hooks'
// constants.
import { selectBudgetsStatus } from '../../../js/budgets/budgetsSelectors'
import { fetchBudgets } from '../../../js/budgets/budgetsSlice'
import {
  AuditReportsDesktopItem,
  AuditReportsDesktopSort,
  AuditReportsDesktopWrapper,
} from '../../../components/screens/AuditReports'
import useFetchAuditsData from '../../../js/cards/hooks/useFetchAuditsData'
import {
  selectActiveFilters,
  selectAuditorsForSelect,
  selectAuditorStatistics,
  selectAuditStatistics,
  selectDoneAuditsWithEppCount,
  selectFilteredAuditorBudget,
  selectFilteredAudits,
  selectFromDateFilter,
  selectLocationFilter,
  selectMonthlyNegativeAuditsByPerson,
  selectSearchTerm,
  selectToDateFilter,
} from '../../../js/audits/auditsSelectors'
import {
  selectFilteredEpps,
  selectFilteredItems,
  selectFilteredItemsConduct,
  selectFilteredItemsOperation,
  selectFilteredPersons,
} from '../../../js/auditData/auditDataSelectors'
import FilterTimeRange from '../../../components/Filters/TimeRange'
import {
  clearFilters,
  removeFilter,
  setFilters,
  setMonthlyNegativeAuditsByPersonFilters,
} from '../../../js/audits/auditsSlice'
import AuditoriesReportHeader from '../../../components/screens/Reports/Charts/AuditoriesReportHeader'
import EppByNameChart from '../../../components/screens/Reports/Charts/EPPByNameChart'
import BudgetByGapChart from '../../../components/screens/Reports/Charts/BudgetByGapChart'
import {
  COLUMN_LAYOUT_END,
  COLUMN_LAYOUT_START,
} from '../../../components/screens/Auditories/DesktopItems/Item'
import AuditAccumulationTable from '../../../components/screens/Reports/Charts/AuditAccumulationTable'
import AudittemsByNameChart from '../../../components/screens/Reports/Charts/AudittemsByNameChart'
import ParetoChart from '../../../components/screens/Reports/Charts/ParetoChart'
import DetailByPersonChart from '../../../components/screens/Reports/Charts/DetailByPersonChart'

// main component.
const StopReportsScreen = ({ navMenu, navTitle }) => {
  // hooks.
  const scrollRef = useRef()
  const chartWrapperRef = useRef(null)
  const { isMobile, width } = useDimensions()
  const loaded = useFetchAuditsData()
  // states.
  const [modalOpen, setModalOpen] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState(false)
  // data.
  const dispatch = useAppDispatch()
  const users = useAppSelector(selectUsersData)
  const locations = useAppSelector(selectAuditsLocationsData)
  const locationsTree = useAppSelector(selectAuditLocationsTree)

  const auditorsSelectOptions = useAppSelector(selectAuditorsForSelect)
  const epps = useAppSelector(selectFilteredEpps())
  const persons = useAppSelector(selectFilteredPersons())
  const conductItems = useAppSelector(selectFilteredItemsConduct())
  const operationItems = useAppSelector(selectFilteredItemsOperation())
  const auditItems = useAppSelector(selectFilteredItems())
  const { done: filteredAudits } = useAppSelector(
    selectFilteredAudits(auditItems, users, locations, persons, epps)
  )
  const auditStatistics = useAppSelector(
    selectAuditStatistics(auditItems, users, locations, persons, epps)
  )
  const auditorStatistics = useAppSelector(
    selectAuditorStatistics(auditItems, users, locations, persons, epps)
  )
  const auditEppItems = useAppSelector(
    selectDoneAuditsWithEppCount(auditItems, users, locations, persons, epps)
  )
  const auditorsBudget = useAppSelector(selectFilteredAuditorBudget)

  const fromDateFilter = useAppSelector(selectFromDateFilter)
  const toDateFilter = useAppSelector(selectToDateFilter)
  const locationFilter = useAppSelector(selectLocationFilter)
  const activeFilters = useAppSelector(selectActiveFilters(users, locationsTree))
  const searchTerm = useAppSelector(selectSearchTerm)

  const budgetsLoaded = useAppSelector(selectBudgetsStatus)
  const monthlyNegativeAuditsByPerson = useAppSelector(
    selectMonthlyNegativeAuditsByPerson(auditItems, users, locations, persons, epps)
  )

  useEffect(() => {
    if (budgetsLoaded === 'idle') dispatch(fetchBudgets())
  }, [dispatch, budgetsLoaded])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  const dynamicColumns = [
    ...new Set(
      conductItems.map(({ id, name }) => {
        return { id, label: name }
      })
    ),
    ...new Set(
      operationItems.map(({ id, name }) => {
        return { id, label: name }
      })
    ),
  ]

  // return content.
  return (
    <>
      <AdminTemplate
        ref={scrollRef}
        sectionTitle={navTitle}
        navigation={navMenu}
        isMobile={isMobile}
        modalOpen={modalOpen}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        filterComponents={[
          <Search
            key="in-audits-search"
            label="Buscar auditoría"
            placeholder="Número..."
            defaultValue={searchTerm}
            onChange={(searchTerm) => dispatch(setFilters({ searchTerm }))}
          />,
          <FilterLocation
            key="in-cards-location"
            label="Ubicación"
            placeholder="Buscar ubicaciones"
            value={locationFilter}
            locationsSelectTree={locationsTree}
            items={filteredAudits}
            onLocationFilter={(locationId) => dispatch(setFilters({ locationId }))}
          />,
          <FilterResponsible
            key="in-audits-responsible"
            label="Observador"
            placeholder="Observador"
            responsibles={auditorsSelectOptions}
            onResponsibleFilter={(auditorId) => dispatch(setFilters({ auditorId }))}
          />,
          <FilterPerson
            key="in-audits-person"
            label="Persona o Empresa Observada"
            placeholder="Elegir Persona o Empresa"
            defaultValue={
              activeFilters.length && activeFilters.find(({ key }) => key === 'isContractor')?.value
            }
            onPersonChange={(isContractor) => dispatch(setFilters({ isContractor }))}
          />,
          <FilterTimeRange
            granularity={['m', 'y']}
            key="in-audits-date-range"
            label="Período de tiempo"
            onFilter={({ fromDate, toDate }) => dispatch(setFilters({ fromDate, toDate }))}
          />,
        ]}
        onOverlayClick={() => setModalOpen(false)}
      >
        <Header justifyContent="space-between">
          {filtersOpen ? null : (
            <>
              <FilterTimeRange
                granularity={['m', 'y']}
                onFilter={({ fromDate, toDate }) => dispatch(setFilters({ fromDate, toDate }))}
              />
              <ToggleFilterButton
                isActive={filtersOpen}
                onChange={(isOpen) => setFiltersOpen(isOpen)}
              />
            </>
          )}
          {isMobile ? null : (
            <>
              <FilterList
                items={activeFilters}
                onFilterClick={(filterValue) => dispatch(removeFilter(filterValue))}
                onClearAllClick={() => dispatch(clearFilters())}
              />
              <Export
                key="in-cards-export"
                header={[...COLUMN_LAYOUT_START, ...dynamicColumns, ...COLUMN_LAYOUT_END]}
                dynamicColumns={dynamicColumns}
                items={filteredAudits}
                filename={`reporte_${new Date().toLocaleDateString()}`}
              />
            </>
          )}
        </Header>

        {loaded && budgetsLoaded === 'succeeded' ? (
          <>
            <ChartsContainer ref={chartWrapperRef} isMobile={isMobile}>
              <ChartCard width="100%">
                <AuditoriesReportHeader
                  fromDate={fromDateFilter}
                  toDate={toDateFilter}
                  auditories={filteredAudits}
                  budget={auditorsBudget}
                />
              </ChartCard>
              {isMobile ? null : (
                <>
                  <AuditReportsDesktopWrapper isEmpty={filteredAudits.length <= 0} width="100%">
                    <AuditReportsDesktopSort dynamicColumns={dynamicColumns} responsibles={users} />
                    {auditStatistics.monthly.map((audit, i) => (
                      <AuditReportsDesktopItem
                        key={`${audit.id}-${i}`}
                        label={
                          (i === 0 && 'Conductas positivas mensual') ||
                          (i === 1 && 'Conductas negativas mensual') ||
                          (i === 2 && '% Positivas (total de obs.)') ||
                          (i === 3 && '% Negativas (total de obs.)')
                        }
                        dynamicColumns={dynamicColumns}
                        item={audit}
                        isGreen={i === 0}
                        isRed={i === 1}
                        isLast={i === auditStatistics.monthly.length - 1}
                      />
                    ))}
                  </AuditReportsDesktopWrapper>
                  <AuditReportsDesktopWrapper isEmpty={filteredAudits.length <= 0}>
                    <AuditReportsDesktopSort dynamicColumns={dynamicColumns} responsibles={users} />
                    {auditStatistics.yearly.map((audit, i) => (
                      <AuditReportsDesktopItem
                        key={`${audit.id}-${i}`}
                        label={
                          (i === 0 && 'Conductas positivas anual') ||
                          (i === 1 && 'Conductas negativas anual') ||
                          (i === 2 && '% Positivas (total de obs.)') ||
                          (i === 3 && '% Negativas (total de obs.)')
                        }
                        dynamicColumns={dynamicColumns}
                        item={audit}
                        isGreen={i === 0}
                        isRed={i === 1}
                        isLast={i === auditStatistics.yearly.length - 1}
                      />
                    ))}
                  </AuditReportsDesktopWrapper>
                </>
              )}
              <ChartCard label="Presupuesto vs Gap" width="34%">
                <BudgetByGapChart auditors={auditorStatistics} />
              </ChartCard>
              <ChartCard label={`EPP que faltaron (${auditEppItems.total})`} width="33%">
                <EppByNameChart epps={epps} items={auditEppItems.items} />
              </ChartCard>
              <ChartCard label={`Auditorias asignadas (${filteredAudits.length})`} width="33%">
                <AuditorDeadlinesTable auditors={auditorStatistics} />
              </ChartCard>
              <ChartCard
                label={`Negativas mensual (${auditStatistics.monthlyNegative.count})`}
                width="20%"
              >
                <AuditAccumulationTable
                  epp={auditStatistics.monthlyNegative.epp}
                  items={auditStatistics.monthlyNegative.items}
                  dynamicColumns={dynamicColumns}
                />
              </ChartCard>
              <ChartCard label="Conductas No Seguras" width="47%" alignItems="flex-start">
                <ParetoChart
                  epp={auditStatistics.monthlyNegative.epp}
                  items={auditStatistics.monthlyNegative.items}
                  dynamicColumns={dynamicColumns}
                  onPersonChange={(isContractor) => dispatch(setFilters({ isContractor }))}
                  activePerson={
                    activeFilters.length && activeFilters.find(({ key }) => key === 'isContractor')
                  }
                />
              </ChartCard>
              <ChartCard
                label={isMobile || width > 1280 ? 'Desglose' : undefined}
                width="33%"
                alignItems="flex-start"
              >
                <DetailByPersonChart
                  dynamicColumns={dynamicColumns}
                  persons={persons}
                  epps={epps}
                  items={monthlyNegativeAuditsByPerson}
                  onItemChange={(value) => {
                    // check if value is a epp
                    const epp = epps.find((epp) => epp.id === value)
                    if (epp) {
                      dispatch(
                        setMonthlyNegativeAuditsByPersonFilters({
                          eppId: value,
                          itemId: 'epp',
                        })
                      )
                    } else {
                      dispatch(
                        setMonthlyNegativeAuditsByPersonFilters({
                          itemId: value,
                          eppId: 'epp',
                        })
                      )
                    }
                  }}
                  onPersonChange={(value) =>
                    dispatch(
                      setMonthlyNegativeAuditsByPersonFilters({
                        personId: value,
                      })
                    )
                  }
                />
              </ChartCard>
              <ChartCard
                label={`Negativas acumulado (${auditStatistics.yearlyNegative.count})`}
                width="20%"
              >
                <AuditAccumulationTable
                  epp={auditStatistics.yearlyNegative.epp}
                  items={auditStatistics.yearlyNegative.items}
                  dynamicColumns={dynamicColumns}
                />
              </ChartCard>
              <ChartCard label="Conductas No Seguras Acumulado" width="80%">
                <AudittemsByNameChart
                  epp={auditStatistics.yearlyNegative.epp}
                  items={auditStatistics.yearlyNegative.items}
                  dynamicColumns={dynamicColumns}
                />
              </ChartCard>
            </ChartsContainer>
          </>
        ) : (
          <Loader />
        )}
      </AdminTemplate>
    </>
  )
}

export default StopReportsScreen

// dependencies.
import styled from 'styled-components'
// components.
// utils.
import { useDimensions } from '../../js/hooks'

/******************************/
/*                            */
/*    Stack List Component    */
/*                            */
/******************************/

// partials.
const StyledList = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: (props) => (props.isTablet ? '100%' : '480px'),
  padding: (props) => (props.isTablet ? '16px 8px 56px' : '56px 16px 0'),
  width: '100%',
  zIndex: 1,
  
  '> *:first-of-type': {
    zIndex: 0,
  },

  '> * + *': {
    marginTop: (props) => (props.isTablet ? '8px' : '16px'),
    zIndex: (props) => props.zEl || 1,
  },
})

// main component.
const Stack = ({ children }) => {
  const { isTablet } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <StyledList zEl={children.length - 1} isTablet={isTablet}>
      {children}
    </StyledList>
  )
}

export default Stack

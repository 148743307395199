// dependencies.
import { useState, useRef, useEffect } from 'react'
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
// components.
import { Breadcrumbs, Tabs } from '../../../components/Layout'
import { Drawer, ShowFrame } from '../../../components/Modal'
import Form from '../../../components/screens/Auditories/Form'
import { Loader } from '../../../components/Icon'
import { Stack } from '../../../components/List'
import AdminTemplate from '../../../templates/AdminPanel'
import BranchSelect from '../../../components/Filters/BranchSelect'
import Header from '../../../components/Layout/Header'
// utils.
import notifyToast from '../../../js/notifyToast'
import { db, getLastAuditNumberByType, getLocationById } from '../../../js/firebase/firebase'
import { useAppDispatch, useAppSelector } from '../../../js/store/hooks'
// constants.
import { COMPLETE_AUDIT_FORM } from '../../Audits/constants'
import { selectUserData } from '../../../js/auth/authSelectors'
import { selectUsersData } from '../../../js/users/usersSelectors'
import {
  selectAuditsLocationsData,
  selectLocationsForAuditsSelect,
  selectLocationsTree,
} from '../../../js/locations/locationsSelectors'
import { selectAuditFilters, selectFilteredAudits } from '../../../js/audits/auditsSelectors'
import { auditUpdated, clearFilters, reload, setFilters } from '../../../js/audits/auditsSlice'
import AuditCard from '../../../components/Card/AuditCard/AuditCard'
import {
  selectEppsForSelect,
  selectFilteredEpps,
  selectFilteredItems,
  selectFilteredItemsConduct,
  selectFilteredItemsOperation,
  selectFilteredPersons,
  selectPersonsForSelect,
} from '../../../js/auditData/auditDataSelectors'
import useFetchAuditsData from '../../../js/cards/hooks/useFetchAuditsData'
import { useParams } from 'react-router'
import FloatingButton from '../../../components/Button/MobileButton'
import { Button } from '../../../components/Button'
import { useDimensions } from '../../../js/hooks'
import { auditDataAdded } from '../../../js/auditData/auditDataSlice'

/****************************/
/*                          */
/*    Aditor Home Screen    */
/*                          */
/****************************/

// helpers.
const buildBreadcrumbs = (array, refId, parents = []) => {
  if (!refId) return null
  for (let i = 0; i < array.length; i++) {
    const obj = array[i]
    if (obj.ref && obj.ref.id === refId) {
      parents.push(obj)
      return parents
    }
    if (Array.isArray(obj.children) && obj.children.length > 0) {
      parents.push(obj)
      const res = buildBreadcrumbs(obj.children, refId, parents)
      if (res) {
        return res
      } else {
        parents.pop()
      }
    }
  }
  return null
}

// tab globales
const Tab = ({
  dispatch,
  items,
  persons = [],
  epps = [],
  conductItems = [],
  operationItems = [],
}) => {
  const [activeStack, setActiveStack] = useState('')
  const [activeIndex, setActiveIndex] = useState(0)
  const [stackContent, setStackContent] = useState([{}])
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)

  const StopAudits = (items && items.filter(({ type }) => type === 'STOP')) || []

  const handleModalOpenFromTab = (open) => setModalOpen(open)

  const handleStackClick = (type, stack, index) => {
    setActiveStack(type)
    setStackContent(stack)
    setActiveIndex(index)
  }

  const handleStackClose = () => {
    setActiveStack('')
    setStackContent([])
    setActiveIndex(0)
  }

  const updateAuditStatus = (auditId) => {
    setStackContent((prevStack) => {
      const updatedStack = prevStack.filter((audit) => audit.id !== auditId)
      if (updatedStack.length === 0) handleStackClose() // Close the stack if there are no audits left
      return updatedStack
    })
  }

  const handleChangeState = async (obj) => {
    obj.locationId = obj.locationRef?.id
    let location = ''
    if (obj.locationRef) location = await getLocationById(obj.locationRef?.id)
    obj.location = location.name
    obj.person = obj.personRef?.id || ''
    obj.auditType = obj.type

    obj.eppRef = null
    obj.eppValue = 3
    const auditItemsSet = [...conductItems, ...operationItems]
    const defaultAuditItems = auditItemsSet.map(({ ref }) => ({ ref, value: 3 }))
    obj.auditItems = defaultAuditItems

    if (obj.status === 0) {
      setModalContent({
        title: `Completar auditoría N° ${obj.number ?? 0}`,
        auditId: obj.ref.id,
        component: (
          <Form
            items={COMPLETE_AUDIT_FORM(persons, epps, conductItems, operationItems)}
            defaultValues={obj}
            onClick={(obj) => handleCompleteAuditClick(obj)}
          />
        ),
      })
    }
    setModalOpen(true)
  }

  const handleCompleteAuditClick = async (obj) => {
    try {
      const auditDataLength = operationItems.length + conductItems.length

      const { ref, auditItems, comments, shift, person, eppValue, eppRef } = obj

      if (auditItems.length !== auditDataLength) {
        notifyToast('Por favor, marque todos los items de la auditoría con una opción', 'error')
        return
      }
      let eppReference = null
      if (eppRef) {
        eppReference = doc(db, 'auditData', eppRef)
      }
      const auditItemEpp = {
        ref: eppReference,
        value: eppValue,
      }
      const personRef = doc(db, 'auditData', person)
      const updateFields = {
        auditItemEpp,
        personRef,
        shift,
        auditItems,
        comments,
        status: 1,
        completedDate: new Date(),
        updatedDate: new Date(),
      }
      const auditRef = doc(db, 'audits', ref.id)
      const originalAuditDoc = await getDoc(auditRef)

      await updateDoc(auditRef, updateFields)
      dispatch(auditUpdated({ id: ref.id, ...originalAuditDoc.data(), ...updateFields }))
      notifyToast('Auditoría actualizada correctamente', 'success')
      updateAuditStatus(ref.id)
      setModalOpen(false)
    } catch (error) {
      console.error('Ha Error al actualizar la auditoría:', error)
      notifyToast('Ha ocurrido un error al actualizar la auditoría', 'error')
    }
  }

  return (
    <>
      <Stack>
        {StopAudits.map((item, i) => (
          <AuditCard
            audit={item}
            key={`${item.id}--${i}`}
            type={item.type}
            isMinimal
            onClick={() => handleStackClick(item.type, StopAudits, i)}
          />
        ))}
      </Stack>
      <ShowFrame
        onCloseClick={() => handleStackClose()}
        activeStack={activeStack}
        activeIndex={activeIndex}
        items={
          stackContent.length > 0 &&
          stackContent.map((item) => {
            return (
              <AuditCard
                key={item.id}
                type={item.type}
                audit={item}
                onActionClick={item.status < 1 ? () => handleChangeState(item) : null}
                actionLabel={item.status === 0 ? 'Completar auditoría' : ''}
              />
            )
          })
        }
      />
      <Drawer
        title={modalContent.title}
        isOpen={modalOpen}
        isLargest
        onChange={handleModalOpenFromTab}
      >
        {modalContent.component}
      </Drawer>
    </>
  )
}

// main component.
const StopHomeScreen = ({ navMenu, navTitle }) => {
  const params = useParams()
  const scrollRef = useRef(null)
  // states.
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)

  const { isMobile } = useDimensions()
  // data.
  const loaded = useFetchAuditsData()
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const selectItemData = useAppSelector(selectLocationsTree)
  const locations = useAppSelector(selectAuditsLocationsData)
  const selectLocations = useAppSelector(selectLocationsForAuditsSelect)
  const users = useAppSelector(selectUsersData)
  const epps = useAppSelector(selectFilteredEpps())
  const eppOptions = useAppSelector(selectEppsForSelect)
  const persons = useAppSelector(selectFilteredPersons())
  const personOptions = useAppSelector(selectPersonsForSelect)
  const conductItems = useAppSelector(selectFilteredItemsConduct())
  const operationItems = useAppSelector(selectFilteredItemsOperation())
  const auditItems = useAppSelector(selectFilteredItems())
  const auditsByStatus = useAppSelector(
    selectFilteredAudits(auditItems, users, locations, persons, epps, userData)
  )
  const auditFilters = useAppSelector(selectAuditFilters)

  // Clean the state before navigate to another screen.
  useEffect(() => {
    const cleanUp = () => {
      dispatch(reload())
    }
    return cleanUp
  }, [params.locationId, dispatch])

  useEffect(() => {
    dispatch(clearFilters())
  }, [dispatch])
  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //
  // open modal.
  const handleModalOpen = (open) => setModalOpen(open)

  // handle add new audit modal.
  const handleNewAuditModal = () => {
    const obj = {}
    obj.auditType = 'STOP'
    obj.location = params.locationId
    obj.status = 0
    obj.createdDate = new Date()
    obj.deadline = new Date()

    obj.eppRef = null
    obj.eppValue = 3
    const auditItemsSet = [...conductItems, ...operationItems]
    const defaultAuditItems = auditItemsSet.map(({ ref }) => ({ ref, value: 3 }))
    obj.auditItems = defaultAuditItems

    setModalContent({
      title: 'Crear auditoría',
      component: (
        <Form
          items={COMPLETE_AUDIT_FORM(
            personOptions,
            eppOptions,
            conductItems,
            operationItems,
            selectLocations
          )}
          defaultValues={obj}
          onClick={(obj) => handleCreateAuditClick(obj)}
        />
      ),
    })

    setModalOpen(true)
  }

  // handle create new card.
  const handleCreateAuditClick = async (obj) => {
    const {
      location: locationId,
      auditType,
      auditItems,
      comments,
      shift,
      person,
      eppValue,
      eppRef,
      ...auditObj
    } = obj

    const lastNumber = await getLastAuditNumberByType(auditType)
    const userRef = doc(db, 'users', userData.uid)
    const locationRef = doc(db, 'locations', locationId)

    let eppReference = null
    if (eppRef) {
      eppReference = doc(db, 'auditData', eppRef)
    }
    const auditItemEpp = {
      ref: eppReference,
      value: eppValue || 3,
    }
    const personRef = doc(db, 'auditData', person)
    const updateFields = {
      ...auditObj,
      number: lastNumber + 1,
      type: auditType,
      auditItemEpp,
      locationRef: locationRef,
      auditorRef: userRef,
      personRef,
      shift,
      auditItems,
      comments,
      status: 1,
      completedDate: new Date(),
      updatedDate: new Date(),
    }

    await addDoc(collection(db, 'audits'), updateFields)
      .then(async (auditRef) => {
        await setDoc(auditRef, { ref: auditRef }, { merge: true })
        const newAuditDoc = await getDoc(auditRef)
        const newAudit = { id: newAuditDoc.id, ...newAuditDoc.data() }
        dispatch(auditDataAdded(newAudit))
        notifyToast('Auditoría completada correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
    dispatch(reload())
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  useEffect(() => {
    dispatch(setFilters({ locationId: params.locationId }))
  }, [params.locationId])

  useEffect(() => {
    setBreadcrumbs(buildBreadcrumbs(selectItemData, auditFilters.locationId))
  }, [selectItemData, auditFilters])

  // tabs content.
  const tabLabels = [
    {
      label: `Pendientes (${auditsByStatus.pending?.length ?? 0})`,
      component: loaded ? (
        <Tab
          dispatch={dispatch}
          items={auditsByStatus.pending}
          persons={personOptions}
          epps={eppOptions}
          conductItems={conductItems}
          operationItems={operationItems}
        />
      ) : (
        <Loader />
      ),
    },
    {
      label: `Realizadas (${auditsByStatus.done?.length >= 99 ? '99+' : auditsByStatus.done?.length ?? 0})`,
      component: loaded ? (
        <Tab
          dispatch={dispatch}
          items={auditsByStatus.done}
          persons={persons}
          epps={epps}
          conductItems={conductItems}
          operationItems={operationItems}
        />
      ) : (
        <Loader />
      ),
    },
  ]

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        {loaded === 'succeeded' ? (
          <>
            <Header justifyContent="space-between">
              <BranchSelect placeholder="Buscar ubicaciones" items={selectItemData} />
              {isMobile ? (
                <FloatingButton onClick={handleNewAuditModal} />
              ) : (
                <Button onClick={handleNewAuditModal}>Nueva Auditoría</Button>
              )}
            </Header>
            <Breadcrumbs pathname="/auditorias/stop" items={breadcrumbs} />
            <Tabs items={tabLabels} ref={scrollRef} />
          </>
        ) : (
          <Loader />
        )}
      </AdminTemplate>
      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default StopHomeScreen

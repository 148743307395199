// dependencies.
import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components'
import versionInfo from './version.json'

// components.
import {
  Activate,
  ActivateOperator,
  AdminAnomalies,
  AdminBudgets,
  AdminCards,
  AdminHome,
  AdminLocations,
  AdminSettings,
  QrDestination,
  Reports,
  Reset,
  Signin,
  Signup,
  Splash,
  StopAssignments,
  StopAudits,
  StopAuditors,
  StopHome,
  StopReports,
  StopSettings,
  Users,
  AuditsLocations,
} from './screens'
// utils.
import { useDimensions } from './js/hooks'
import { useAppSelector } from './js/store/hooks'
// constants.
import { DEV_NAVIGATION_ITEMS, NAVIGATION_ITEMS, SIGN_UP_ENABLED } from './constants'
import { selectUserData } from './js/auth/authSelectors'
import { useState, useEffect } from 'react'
import { getCookie, setCookie } from './js/cookies'

/************************/
/*                      */
/*    App and Screen    */
/*                      */
/************************/

// partials:
const Wrapper = styled.div(
  {
    height: (props) => `${props.height}px`,
    width: (props) => `${props.width}px`,
  },
  `@media print { height: auto; width: auto; }`
)

// main component:
const App = () => {
  // hooks.
  const userData = useAppSelector(selectUserData)
  const dimensions = useDimensions()
  const [reloadApp, setReloadApp] = useState(false)

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  const NAV_ITEMS =
    !userData?.access || userData?.access === 'developer'
      ? DEV_NAVIGATION_ITEMS
      : NAVIGATION_ITEMS(userData?.isAuditor)[userData?.access]

  useEffect(() => {
    const currentVersion = versionInfo.version
    const storedVersion = getCookie('appVersion')

    if (!storedVersion || storedVersion !== currentVersion) {
      // If stored version is different from current version.
      setCookie('appVersion', currentVersion, 30)
      if (!storedVersion) {
        window.location.reload(true)
      } else {
        setReloadApp(true)
      }
    } else {
      // If version is the same.
      setCookie('appVersion', currentVersion, 30)
    }
  }, [])

  // return content.
  return reloadApp ? (
    <div id="reload-app">
      <p>
        Hay una nueva versión disponible,
        <br />
        por favor recargue la aplicación.
      </p>
      <button onClick={() => window.location.reload(true)}>Recargar App</button>
    </div>
  ) : (
    <Wrapper {...dimensions}>
      <Routes>
        {/* Splash Screen */}
        <Route path="/" element={<Splash />} />
        <Route path="/redirect/:locationId" element={<QrDestination />} />
        {/* Auth Screens */}
        <Route path="/activar" element={<Activate />} />
        <Route path="/activar-funcionario" element={<ActivateOperator />} />
        <Route path="/forgot-password" element={<Reset />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        {SIGN_UP_ENABLED && <Route path="/signup" element={<Signup />} />}
        {/* Admin Screens */}
        <Route path="/admin">
          <Route index element={<AdminHome navMenu={NAV_ITEMS} navTitle="Inicio" />} />
          <Route path=":locationId" element={<AdminHome navMenu={NAV_ITEMS} navTitle="Inicio" />} />
          <Route
            path="ajustes"
            element={<AdminSettings navMenu={NAV_ITEMS} navTitle="Ajustes" />}
          />
          <Route path="usuarios" element={<Users navMenu={NAV_ITEMS} navTitle="Usuarios" />} />
          <Route
            path="anomalias"
            element={<AdminAnomalies navMenu={NAV_ITEMS} navTitle="Anomalías" />}
          />
          <Route
            path="presupuestos"
            element={<AdminBudgets navMenu={NAV_ITEMS} navTitle="Presupuestos" />}
          />
          <Route path="reportes" element={<Reports navMenu={NAV_ITEMS} navTitle="Reportes" />} />
          <Route
            path="ubicaciones"
            element={<AdminLocations navMenu={NAV_ITEMS} navTitle="Plantas" />}
          />
          <Route
            path="ubicaciones/:locationId"
            element={<AdminLocations navMenu={NAV_ITEMS} navTitle="Ubicaciones" />}
          />
          <Route path="tarjetas" element={<AdminCards navMenu={NAV_ITEMS} navTitle="Tarjetas" />} />
        </Route>
        {/* Operator screens */}
        <Route path="/funcionario">
          <Route index element={<AdminHome navMenu={NAV_ITEMS} navTitle="Inicio" />} />
          <Route
            path=":locationId"
            element={<AdminHome navMenu={NAV_ITEMS} navTitle="Tarjetas" />}
          />
          <Route
            path="ajustes"
            element={<AdminSettings navMenu={NAV_ITEMS} navTitle="Ajustes" />}
          />
        </Route>
        {/* Auditor screens */}
        <Route path="/auditorias/stop">
          <Route index element={<StopHome navMenu={NAV_ITEMS} navTitle="Mis auditorías" />} />
          <Route
            path=":locationId"
            element={<StopHome navMenu={NAV_ITEMS} navTitle="Mis auditorías" />}
          />
          <Route
            index
            path="asignaciones"
            element={<StopAssignments navMenu={NAV_ITEMS} navTitle="Asignaciones" />}
          />
          <Route
            path="auditorias"
            element={<StopAudits navMenu={NAV_ITEMS} navTitle="Auditorías" />}
          />
          <Route
            path="reportes"
            element={<StopReports navMenu={NAV_ITEMS} navTitle="Reportes" />}
          />
          <Route
            path="auditores"
            element={<StopAuditors navMenu={NAV_ITEMS} navTitle="Auditores" />}
          />
          <Route
            path="ubicaciones"
            element={<AuditsLocations navMenu={NAV_ITEMS} navTitle="Ubicaciones" />}
          />
          <Route
            path="configuracion"
            element={<StopSettings navMenu={NAV_ITEMS} navTitle="Configuración" />}
          />
        </Route>
      </Routes>
    </Wrapper>
  )
}

export default App
